import React, { forwardRef, ForwardRefRenderFunction } from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import {
  Button,
  BUTTON_INTENT,
  BUTTON_SIZE,
  FIELD_TONE,
  FlexBox,
  Modal,
  ModalWrap,
  Portal,
  TShutterHandler
} from '@monorepo/ui-kit';

import { TBlogCard } from '../../types';
import BlogContent from '../BlogContent';

import styles from './index.module.css';

type TShutterCancelBonus = {
  handleClose: () => void;
  classOverlayModal?: string;
  blog: TBlogCard;
};

const BlogPreview: ForwardRefRenderFunction<
  TShutterHandler,
  TShutterCancelBonus
> = ({ handleClose, classOverlayModal = '', blog }, ref) => {
  const { t } = useI18n(NAMESPACES.SOCIAL);

  return (
    <Portal>
      {/* @ts-ignore */}
      <Modal
        classOverlay={classOverlayModal}
        classContent={styles.bg}
        ref={ref}
        isHasOverlay
        alwaysCenter
      >
        <ModalWrap
          header={t('blogs.preview')}
          handleClose={handleClose}
          headerClassName={styles.headerModal}
          wrapClassName={styles.wrapModal}
        >
          <FlexBox className={styles.root}>
            <BlogContent blog={blog} isPreview />
          </FlexBox>
          <Button
            toneType={FIELD_TONE.light}
            intent={BUTTON_INTENT.primary}
            onClick={handleClose}
            size={BUTTON_SIZE.h40}
            className={styles.button}
          >
            {t('blogs.preview.close')}
          </Button>
        </ModalWrap>
      </Modal>
    </Portal>
  );
};
export default forwardRef(BlogPreview);
