import React from 'react';
import { useParams } from 'react-router-dom';

import { useIsAuthenticated } from '@monorepo/auth';
import { EmptyState } from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';

import GameChat from '../GameChat';

const GameChatStandAlone = () => {
  const { t } = useI18n(NAMESPACES.SOCIAL);
  const { roomId = 0 } = useParams<{ roomId: string }>();
  const { isAuthenticated } = useIsAuthenticated();
  const room = Number(roomId);

  if (isAuthenticated) return <GameChat gameId={room} config="" />;

  return (
    <EmptyState
      icon="user"
      title={t('social.session.lost')}
      subtitle={t('social.session.reload.page')}
    />
  );
};
export default GameChatStandAlone;
