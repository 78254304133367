import React from 'react';

import { PageWrapper } from '@monorepo/page';
import { CustomFC } from '@monorepo/type';

import componentsMap from './componentsMap';
import pages from './pages';

const Page: CustomFC = ({ children }) => (
  <PageWrapper pages={pages} componentsMap={componentsMap}>
    {children}
  </PageWrapper>
);

export default Page;
