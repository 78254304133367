import React from 'react';

import {
  InputField,
  requiredValidator,
  SelectField,
  TextAreaField
} from '@monorepo/forms';
import { toSelectOptions } from '@monorepo/helpers';
import { LANGUAGE_CODES_MAP } from '@monorepo/i18n';

type TOption = {
  label: string;
  value: string;
};

const DefaultFields = () => {
  const languages = Object.values(LANGUAGE_CODES_MAP);

  return (
    <>
      <TextAreaField
        id="authToken"
        name="authToken"
        label="Token"
        placeholder="Enter token"
        rules={{ ...requiredValidator() }}
      />
      <InputField
        id="userId"
        name="userId"
        label="User Id(Required only for dreamtown)"
        placeholder="Enter user id"
      />
      <InputField
        id="partnerId"
        name="partnerId"
        label="Partner Id"
        placeholder="Enter partner id"
        rules={{ ...requiredValidator() }}
      />
      <SelectField
        id="language"
        name="language"
        label="Select language"
        rules={{ ...requiredValidator() }}
        options={toSelectOptions(languages, '', '').map(
          ({ label, value }): TOption => ({
            label: label as string,
            value
          })
        )}
      />
    </>
  );
};

export default DefaultFields;
