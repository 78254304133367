import React, { forwardRef, ForwardRefRenderFunction } from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import {
  Button,
  BUTTON_INTENT,
  BUTTON_SIZE,
  FLEX_JUSTIFY,
  FlexBox,
  Modal,
  ModalWrap,
  Portal,
  TShutterHandler
} from '@monorepo/ui-kit';

import styles from './index.module.css';

type TShutterCancelBonus = {
  handleClose: () => void;
  handleAccept: () => void;
  classOverlayModal?: string;
  classContentModal?: string;
};

const ShutterDeleteBlog: ForwardRefRenderFunction<
  TShutterHandler,
  TShutterCancelBonus
> = (
  { handleClose, handleAccept, classOverlayModal = '', classContentModal = '' },
  ref
) => {
  const { t } = useI18n(NAMESPACES.SOCIAL);

  return (
    <Portal>
      {/* @ts-ignore */}
      <Modal
        classOverlay={classOverlayModal}
        classContent={classContentModal}
        ref={ref}
        isHasOverlay
      >
        <ModalWrap header={t('blogs.delete')} handleClose={handleClose}>
          <span className={styles.description}>
            {t('blogs.delete.confirmation')}
          </span>
          <FlexBox justify={FLEX_JUSTIFY.between}>
            <Button
              size={BUTTON_SIZE.h40}
              className={styles.button}
              onClick={handleClose}
            >
              {t('blogs.delete.confirmation_no')}
            </Button>
            <Button
              intent={BUTTON_INTENT.secondary}
              size={BUTTON_SIZE.h40}
              className={styles.button}
              onClick={handleAccept}
            >
              {t('blogs.delete.confirmation_yes')}
            </Button>
          </FlexBox>
        </ModalWrap>
      </Modal>
    </Portal>
  );
};
export default forwardRef(ShutterDeleteBlog);
