import React from 'react';

import { Icon } from '@monorepo/icons';
import { Button, BUTTON_INTENT, useShutterRef } from '@monorepo/ui-kit';

import useGetBlogDelete from '../../hooks/useGetBlogDelete';
import ShutterDeleteBlog from '../ShutterDeleteBlog';

import styles from './index.module.css';

const DeleteBlog = ({
  id,
  className = ''
}: {
  id: number;
  className?: string;
}) => {
  const { handleDeleteBlog } = useGetBlogDelete();
  const { shutterRef, setShow, setHide } = useShutterRef();

  const handleAccept = () => {
    handleDeleteBlog(id);
    setHide();
  };

  return (
    <>
      <ShutterDeleteBlog
        handleClose={setHide}
        handleAccept={handleAccept}
        ref={shutterRef}
      />
      <Button
        onClick={setShow}
        isOnlyIcon
        className={`${className} ${styles.delete}`}
        intent={BUTTON_INTENT.transparent}
      >
        <Icon name="bin_0" className={styles.icon} />
      </Button>
    </>
  );
};
export default DeleteBlog;
