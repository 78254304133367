import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUpdateBlogMutation } from '@monorepo/common/src/store/userGeneratedContentApi';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { useSiteSettings } from '@monorepo/site-settings';

const useBlogUpdate = () => {
  const { t } = useI18n(NAMESPACES.SOCIAL);
  const { generalRoutes } = useSiteSettings();
  const { blogs } = generalRoutes;
  const { errorNotification, successNotification } = useNotifications();
  const [onSave] = useUpdateBlogMutation();
  const navigate = useNavigate();

  const save = useCallback(
    async (args) => {
      const {
        title,
        description,
        body,
        linkToVideo,
        linkToPicture,
        linkToEvent,
        startTime,
        endTime,
        tags,
        id
      } = args;
      const { data, error } = await onSave({
        id,
        title,
        description,
        body,
        linkToVideo,
        linkToPicture,
        linkToEvent,
        startTime,
        endTime,
        tags
      });
      if (error) {
        errorNotification(`${error.data.message}: ${error.data.key}`);
      } else {
        successNotification(t('blogs.updated', { id: data?.id }));
        navigate(`${blogs}/${data.id}`);
      }
    },
    [onSave, errorNotification]
  );

  return {
    save
  };
};

export default useBlogUpdate;
