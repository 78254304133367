import { useCallback } from 'react';

import { useUploadImage } from '@monorepo/files';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';

const useSaveImage = () => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const { errorNotification } = useNotifications();
  const { upload, isLoading: isLoadingUpload } = useUploadImage();

  const save = useCallback(
    async (file: File) => {
      try {
        const data = await upload(file).unwrap();
        return data;
      } catch {
        errorNotification(t('image.error'));
        return null;
      }
    },
    [upload, errorNotification]
  );

  return {
    save,
    isLoading: isLoadingUpload
  };
};

export default useSaveImage;
