import React from 'react';

import {
  CheckboxField,
  InputField,
  requiredValidator,
  SelectField
} from '@monorepo/forms';
import { EXTERNAL_CHAT_SLUG } from '@monorepo/messenger';

const ExternalChatFields = () => (
  <>
    <SelectField
      id="frameSlug"
      name="slug"
      label="External chat slug"
      placeholder="Select external slug"
      options={[
        {
          value: '',
          label: 'none'
        },
        {
          value: EXTERNAL_CHAT_SLUG.SPORT_EVENT,
          label: EXTERNAL_CHAT_SLUG.SPORT_EVENT
        },
        {
          value: EXTERNAL_CHAT_SLUG.CASINO_GAME,
          label: EXTERNAL_CHAT_SLUG.CASINO_GAME
        }
      ]}
    />
    <InputField
      key="1"
      id="frameExternalID"
      name="externalId"
      label="Chat external Id*"
      placeholder="Enter chat external id"
      rules={{ ...requiredValidator() }}
    />
    <CheckboxField label="ReedOnly" id="readOnly" name="readOnly" />
  </>
);

export default ExternalChatFields;
