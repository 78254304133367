import React from 'react';
import { useFormContext } from 'react-hook-form';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import {
  Button,
  BUTTON_INTENT,
  BUTTON_SIZE,
  FIELD_TONE,
  useShutterRef
} from '@monorepo/ui-kit';

import { TBlogCard } from '../../types';
import BlogPreview from '../BlogPreview';

import styles from './index.module.css';

const BlogPreviewButton = ({ classNamePreview = '' }) => {
  const { shutterRef, setShow, setHide } = useShutterRef();
  const { t } = useI18n(NAMESPACES.SOCIAL);
  const { watch } = useFormContext();
  const fields = watch();
  return (
    <>
      <BlogPreview
        handleClose={setHide}
        ref={shutterRef}
        blog={fields as TBlogCard}
      />
      <Button
        onClick={setShow}
        className={`${styles.preview} ${classNamePreview}`}
        toneType={FIELD_TONE.custom}
        intent={BUTTON_INTENT.transparent}
        size={BUTTON_SIZE.h40}
      >
        <Icon name="eye_0" className={styles.icon} />
        {t('blogs.preview')}
      </Button>
    </>
  );
};
export default BlogPreviewButton;
