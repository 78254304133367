import { useCallback } from 'react';

import { BLOG_UPLOAD_SIZES, FILE_SOURCE } from '../../constants';
import { useUploadFileMutation } from '../../store/mediaStorageApi';

const useUploadImage = () => {
  const [uploadFile, { isLoading }] = useUploadFileMutation();

  const upload = useCallback(
    (file: File) =>
      uploadFile({
        file,
        source: FILE_SOURCE.UGC,
        sizes: BLOG_UPLOAD_SIZES
      }),
    [uploadFile]
  );

  return { upload, isLoading };
};

export default useUploadImage;
