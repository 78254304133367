import React, { Dispatch, SetStateAction } from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';

const useUploadFile = ({
  uploadMaxSize,
  setFileName,
  formContextRef,
  refImage,
  saveImage,
  fieldName
}: {
  uploadMaxSize: number;
  setFileName: Dispatch<SetStateAction<string>>;
  formContextRef: React.MutableRefObject<any | null>;
  refImage: React.MutableRefObject<undefined>;
  saveImage: (file: File) => Promise<any>;
  fieldName: string;
}) => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const { errorNotification } = useNotifications();
  const onChangeHandler = async (e: any) => {
    if (e.target.files[0].size / 1000000 < uploadMaxSize) {
      const filename = await saveImage(e.target.files[0]);
      if (filename) {
        const { urls } = filename;
        setFileName(urls.original);
        formContextRef.current.setValue(fieldName, urls.original, {
          shouldDirty: true
        });
      }
    } else {
      errorNotification(
        t(`image.size.error`, {
          fileSize: uploadMaxSize
        })
      );
      // eslint-disable-next-line no-param-reassign
      refImage.current.value = null;
    }
  };
  return { onChangeHandler };
};

export default useUploadFile;
