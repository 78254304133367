import { TFullPage } from '@monorepo/page';

import page404 from './pages/404.json';
import balance from './pages/balance.json';
import blogs from './pages/blogs.json';
import blogAdd from './pages/blogs-add.json';
import blogsEdit from './pages/blogs-edit.json';
import blogsList from './pages/blogs-list.json';
import bonuses from './pages/bonuses.json';
import bonusesExtended from './pages/bonuses-expended.json';
import casino from './pages/casino.json';
import casinoCategory from './pages/casino-category.json';
import casinoLauncher from './pages/casino-launcher.json';
import casinoRecommended from './pages/casino-recommended.json';
import casinoRedirect from './pages/casino-redirect.json';
import chats from './pages/chats.json';
import communityChats from './pages/chats-communities.json';
import chatsCommunity from './pages/chats-community.json';
import communityChat from './pages/chats-community-chat.json';
import chatsCommunityStandAlone from './pages/chats-community-stand-alone.json';
import personalChats from './pages/chats-personal.json';
import personalChat from './pages/chats-personal-chat.json';
import chatsProfile from './pages/chats-profile.json';
import contentPage from './pages/contentPage.json';
import editProfile from './pages/edit-profile.json';
import editProfileChangePassword from './pages/edit-profile-change-password.json';
import editProfileContacts from './pages/edit-profile-contacts.json';
import editProfileMain from './pages/edit-profile-main.json';
import editProfileNickname from './pages/edit-profile-nickname.json';
import editProfilePersonalData from './pages/edit-profile-personal-data.json';
import editProfileVerifyAccount from './pages/edit-profile-verify-account.json';
import empty from './pages/empty.json';
import event from './pages/event.json';
import favorite from './pages/favorites.json';
import favoritesCasino from './pages/favorites-casino.json';
import favoriteSports from './pages/favorites-sports.json';
import favoritVideo from './pages/favorites-video.json';
import favoritWall from './pages/favorites-wall.json';
import favoritSportsCompetitions from './pages/favoritSportsCompetitions.json';
import favoriteSportsMatches from './pages/favoritSportsMatches.json';
import home from './pages/home.json';
import live from './pages/live.json';
import liveEvent from './pages/live-event.json';
import liveCompetition from './pages/liveCompetition.json';
import liveRegion from './pages/liveRegion.json';
import liveSport from './pages/liveSport.json';
import login from './pages/login.json';
import betsHistory from './pages/my-bets.json';
import betsHistoryAll from './pages/my-bets-all.json';
import betsHistoryCashed from './pages/my-bets-cashed.json';
import betsHistoryLive from './pages/my-bets-live.json';
import betsHistoryOpen from './pages/my-bets-open.json';
import prematch from './pages/prematch.json';
import preMatchCompetition from './pages/prematchCompetition.json';
import prematchPopular from './pages/prematchPopular.json';
import prematchRecommended from './pages/prematchRecommended.json';
import prematchRegion from './pages/prematchRegion.json';
import prematchSport from './pages/prematchSport.json';
import profile from './pages/profile.json';
import profilePreferables from './pages/profile-preferables.json';
import profilePreferablesCustom from './pages/profile-preferables-custom.json';
import profileVisibility from './pages/profile-visibility.json';
import promos from './pages/promos.json';
import promosSlots from './pages/promosSlots.json';
import promotion from './pages/promotion.json';
import promotionsSlot from './pages/promotionSlot.json';
import register from './pages/register.json';
import registerSuccess from './pages/register-success.json';
import restorePassword from './pages/restore-password.json';
import restorePasswordSuccess from './pages/restore-password-success.json';
import searchCasino from './pages/searchCasino.json';
import searchSports from './pages/searchSports.json';
import setPassword from './pages/set-password.json';
import sportResults from './pages/sport-results.json';
import testMessengerFrames from './pages/test_messenger_frames.json';
import userNotification from './pages/user-notification.json';
import userNotificationMain from './pages/user-notification-main.json';
import userNotificationMessage from './pages/user-notification-message.json';
import verificationBankId from './pages/verification-bank-id.json';
import verifyEmailSuccess from './pages/verify-email-success.json';
import verifyEmail from './pages/verifying-email.json';
import wall from './pages/wall.json';
import wallCategory from './pages/wallCategory.json';
import walletDeposit from './pages/walletDeposit.json';
import walletHistory from './pages/walletHistory.json';
import walletWithdraw from './pages/walletWithdraw.json';

const pages: { [pathname: string]: TFullPage } = {
  '/': casino,
  '/home': home,
  // casino
  '/casino': casinoRedirect,
  '/casino/lobby': casino,
  '/casino/recommended': casinoRecommended,
  '/casino/launcher': casinoLauncher,
  '/casino/:categoryId': casinoCategory,

  // common
  '/404': page404,
  '/login': login,

  '/restore-password': restorePassword,
  '/restore-password/success': restorePasswordSuccess,
  '/need-to-verify-email': verifyEmail,
  '/verify-email/:code': verifyEmailSuccess,
  '/set-password': setPassword,
  '/sign-up': register,
  '/sign-up/success': registerSuccess,
  '/notifications/': userNotificationMain,
  '/notifications/:slug': userNotification,
  '/notifications/message/:messageId': userNotificationMessage,
  '/content-page/:snippetId': contentPage,
  '/wall': wall,
  '/wall/all': wallCategory,
  '/wall/:category': wallCategory,
  '/wall/slots': promosSlots,
  '/promo/slots/:id': promotionsSlot,
  '/promo': promos,
  '/promo/:id': promotion,
  // socials
  '/chats': chats,
  '/chats/personal': personalChats,
  '/chats/personal/:roomId': personalChat,
  '/chats/community': communityChats,
  '/chats/community/:roomId': communityChat,
  '/community/:roomId': chatsCommunity,
  '/community-standalone/:roomId': chatsCommunityStandAlone, // todo delete, check components for delete
  // sports
  '/sports/top': prematchRecommended,
  '/sports/top/:sportAlias/:regionAlias/:competitionId': prematchRecommended,
  '/sports': prematch,
  '/sports/prematch': prematch,
  '/sports/prematch/popular': prematchPopular,
  '/sports/prematch/:sportAlias': prematchSport,
  '/sports/prematch/:sportAlias/:regionAlias': prematchRegion,
  '/sports/prematch/:sportAlias/:regionAlias/:competitionId':
    preMatchCompetition,
  '/sports/prematch/:sportAlias/:regionAlias/:competitionId/:gameId': event,
  '/sports/live': live,
  '/sports/live/:sportAlias': liveSport,
  '/sports/live/:sportAlias/:regionAlias': liveRegion,
  '/sports/live/:sportAlias/:regionAlias/:competitionId': liveCompetition,
  '/sports/live/:sportAlias/:regionAlias/:competitionId/:gameId': liveEvent,
  '/sports/results': sportResults,
  // search
  '/search/sports': searchSports,
  '/search/casino': searchCasino,
  // favorites
  '/favorites': favorite,
  '/favorites/sports': favoriteSports,
  '/favorites/sports/matches': favoriteSportsMatches,
  '/favorites/sports/competitions': favoritSportsCompetitions,
  '/favorites/casino': favoritesCasino,
  '/favorites/wall': favoritWall,
  '/favorites/video': favoritVideo,
  // account
  '/account/profile': profile,
  '/account/profile/:id': chatsProfile,
  '/account/profile/preferables': profilePreferables,
  '/account/profile/preferables/custom-button': profilePreferablesCustom,
  '/account/profile/preferables/profile-visibility': profileVisibility,
  '/account/profile/edit': editProfile,
  '/account/profile/edit/main': editProfileMain,
  '/account/profile/edit/contacts': editProfileContacts,
  '/account/profile/edit/nickname': editProfileNickname,
  '/account/profile/edit/personal-data': editProfilePersonalData,
  '/account/profile/edit/change-password': editProfileChangePassword,
  '/account/profile/edit/verification-bank-id': verificationBankId,
  '/account/profile/edit/verify-account': editProfileVerifyAccount,
  '/account/balance': balance,
  '/account/balance/deposit': walletDeposit,
  '/account/balance/withdraw': walletWithdraw,
  '/account/balance/history': walletHistory,
  '/account/personal-data': empty,
  '/account/bonuses/casino_bonus': bonuses,
  '/account/bonuses/:bonusType': bonuses,
  '/account/bonuses/:bonusType/:bonusId': bonusesExtended,
  '/account/bets/history': betsHistory,
  '/account/bets/history/open': betsHistoryOpen,
  '/account/bets/history/all': betsHistoryAll,
  '/account/bets/history/cashed': betsHistoryCashed,
  '/account/bets/history/live': betsHistoryLive,
  // blogs
  '/blogs/': blogsList,
  '/blogs/:id': blogs,
  '/blogs/add': blogAdd,
  '/blogs/:id/edit': blogsEdit,
  '/blogs/category/:id': blogsList,
  '/test_messenger_frames': testMessengerFrames
};

export default pages;
