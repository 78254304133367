import React from 'react';
import { useParams } from 'react-router-dom';

import { BackHeaderCommon, useScrollToTop } from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import { ComponentWithConfig } from '@monorepo/type/src';
import {
  FIELD_TONE,
  FLEX_DIRECTION,
  FlexBox,
  PaddingWrapper
} from '@monorepo/ui-kit';

import useGetBlog from '../../hooks/useGetBlog';
import { TBlogMod } from '../../types';

import EditForm from './component/EditForm';

import styles from './index.module.css';

const BlogsEdit: ComponentWithConfig<TBlogMod> = ({ config }) => {
  const { t } = useI18n(NAMESPACES.SOCIAL);
  const { id: pageId } = useParams<Record<string, string>>();
  const { data } = useGetBlog(pageId);
  const { generalRoutes } = useSiteSettings();
  const { blogs } = generalRoutes;
  const { files, uploadMaxSize } = config;
  const {
    title,
    description,
    linkToPicture,
    body,
    linkToVideo,
    playerId,
    endTime,
    startTime,
    createTime,
    tags = [],
    id
  } = data || {};

  const defaultValues = {
    id,
    title,
    description,
    body,
    playerId,
    linkToVideo,
    linkToPicture,
    createTime,
    startTime,
    endTime,
    tags: tags[0] || ''
  };

  useScrollToTop();

  return (
    <PaddingWrapper className={styles.root}>
      <FlexBox direction={FLEX_DIRECTION.column}>
        <BackHeaderCommon
          title={t('blogs.back')}
          backLink={blogs}
          tone={FIELD_TONE.light}
        />
        <EditForm
          defaultValues={defaultValues}
          files={files}
          uploadMaxSize={uploadMaxSize}
        />
      </FlexBox>
    </PaddingWrapper>
  );
};
export default BlogsEdit;
