import React from 'react';
import { useFormState, ValidationMode } from 'react-hook-form';

import { CustomFC } from '@monorepo/type';
import { Button, ButtonProps } from '@monorepo/ui-kit';

type Props = {
  mode?: keyof ValidationMode;
} & Omit<ButtonProps, 'type'>;

const SubmitButton: CustomFC<Props> = ({
  children,
  processing,
  disabled,
  mode = 'onChange',
  ...props
}) => {
  const { isSubmitting, isValid, isValidating, dirtyFields } = useFormState();
  const isOnSubmit = mode === 'onSubmit';
  const isNotChanged = Object.keys(dirtyFields).length === 0;
  return (
    <Button
      type="submit"
      disabled={(!isOnSubmit && !isValid) || isNotChanged || disabled}
      processing={isSubmitting || isValidating || processing}
      {...props}
    >
      {children}
    </Button>
  );
};

export default SubmitButton;
