import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import addDays from 'date-fns/addDays';

import { DATE_FORMAT } from '@monorepo/common';
import { DateField } from '@monorepo/forms';
import {
  formatDate,
  fromString,
  toSecondsDate,
  toUnix
} from '@monorepo/helpers';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { FIELD_TONE, FLEX_DIRECTION, FlexBox } from '@monorepo/ui-kit';

import styles from './index.module.css';

const BlogDateRange = () => {
  const { t } = useI18n(NAMESPACES.SOCIAL);
  const { setValue, getValues } = useFormContext();
  const startTime = getValues('startTime');
  const current = new Date();
  const minDate = addDays(fromString(startTime, DATE_FORMAT.LONG), 1);
  const [minEndDate, setMinEndDate] = useState(minDate);
  const endDateField = toUnix(getValues('endTime'), DATE_FORMAT.LONG) as number;

  const handleStartChange = (value: Date) => {
    const endSetTime = addDays(value, 1);
    const newStartDate = toSecondsDate(value) as number;
    setMinEndDate(endSetTime);
    if (endDateField - newStartDate < 86400) {
      setValue('endTime', formatDate(endSetTime, DATE_FORMAT.LONG));
    }
  };

  return (
    <FlexBox direction={FLEX_DIRECTION.column} className={styles.dateRow}>
      <DateField
        id="startTime"
        name="startTime"
        label={`${t('blogs.date.start.label')} *`}
        placeholder="DD.MM.YYYY HH:mm"
        toneType={FIELD_TONE.light}
        iconName="calendar_and_time"
        formatString={DATE_FORMAT.LONG}
        minDate={current}
        onChange={handleStartChange}
        isRequired
        showTimeInput
      />
      <DateField
        id="endTime"
        name="endTime"
        label={`${t('blogs.date.end.label')} *`}
        placeholder="DD.MM.YYYY HH:mm"
        toneType={FIELD_TONE.light}
        iconName="calendar_and_time"
        formatString={DATE_FORMAT.LONG}
        minDate={minEndDate}
        showTimeInput
      />
    </FlexBox>
  );
};
export default BlogDateRange;
