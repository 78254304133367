import React from 'react';
import { Link } from 'react-router-dom';

import { transformCmsIcons } from '@monorepo/cms';
import { LIKE_TYPE } from '@monorepo/common';
import { formatUnixTime } from '@monorepo/helpers';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';
import { Component } from '@monorepo/type';
import {
  Box,
  FLEX_ALIGN,
  FLEX_JUSTIFY,
  FlexBox,
  PreloadImage,
  UI_SIZES
} from '@monorepo/ui-kit';

import { TBlogCard } from '../../types';
import BlogAvatar from '../BlogAvatar';
import BlogCardInfo from '../BlogCardInfo';
import BlogsCategory from '../BlogsCategory';

import styles from './index.module.css';

type TBlogCardProps = {
  item: TBlogCard;
  className: string;
};

const BlogCard: Component<TBlogCardProps> = ({ item, className = '' }) => {
  const { generalRoutes } = useSiteSettings();
  const { blogs } = generalRoutes;
  const { t } = useI18n(NAMESPACES.SOCIAL);
  const {
    title,
    description,
    createTime,
    viewsCount,
    linkToPicture,
    id,
    playerId,
    tags
  } = item;
  const tag = tags[0];
  return (
    <Box className={`${styles.wrap} ${className}`}>
      <Link to={`${blogs}/${id}`} className={styles.link} />
      <PreloadImage
        src={linkToPicture}
        alt={title}
        className={styles.preload}
        imgClassName={styles.img}
      >
        <Icon name="info" className={styles.fallback} />
      </PreloadImage>
      <Box className={styles.title}>{title}</Box>
      <Box className={styles.description}>{description}</Box>
      <FlexBox
        justify={FLEX_JUSTIFY.between}
        align={FLEX_ALIGN.center}
        mb={UI_SIZES.m}
      >
        <Box className={styles.date}>
          {formatUnixTime(createTime, 'dd.MM.yyyy, HH:mm')} | {viewsCount}{' '}
          {t('blogs.views')}
        </Box>
        <BlogCardInfo
          className={styles.info}
          id={id}
          title={title}
          type={LIKE_TYPE.POST}
          href={`${blogs}/${id}`}
        />
      </FlexBox>
      <FlexBox
        align={FLEX_ALIGN.center}
        className={styles.titleWrap}
        justify={FLEX_JUSTIFY.between}
      >
        <BlogAvatar className={styles.author} userId={playerId.toString()} />
        <BlogsCategory
          src={`${blogs}/category/${tag}`}
          icon={transformCmsIcons(`blogs-category-${tag}`)}
          slug={tag}
          className={styles.category}
          iconClassName={styles.categoryIcon}
        />
      </FlexBox>
    </Box>
  );
};

export default BlogCard;
