import { useEffect } from 'react';

type TPostMessageListenerCallback<T> = (event: MessageEvent<T>) => void;

const usePostMessageListener = <T extends Record<string, unknown>>(
  callback: TPostMessageListenerCallback<T>
) => {
  useEffect(() => {
    const handler = (event: MessageEvent<T>) => {
      callback?.(event);
    };

    window.addEventListener('message', handler, false);
    return () => window.removeEventListener('message', handler);
  }, []);
};

export default usePostMessageListener;
