import React, { useMemo } from 'react';

import { useIsAuthenticated } from '@monorepo/auth';
import { transformCmsIcons } from '@monorepo/cms';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import { Component } from '@monorepo/type';
import { Box, SlideMenu, TabsNavigation } from '@monorepo/ui-kit';

import useGetBlogsCategory from '../../../../hooks/useGetBlogsCategory';

import styles from './index.module.css';

type Props = {
  isWithButtons?: boolean;
};

type TCategory = {
  slug: string;
};

const Category: Component<Props> = ({
  isWithButtons = false,
  className = ''
}) => {
  const { isAuthenticated } = useIsAuthenticated();
  const { t } = useI18n(NAMESPACES.SOCIAL);
  const { generalRoutes } = useSiteSettings();
  const { blogs } = generalRoutes;
  const { data } = useGetBlogsCategory();

  const items = useMemo(() => {
    const myItem = {
      title: t('blogs.category.my'),
      link: `${blogs}/category/my`,
      id: 'my'
    };
    const allItem = {
      title: t('blogs.category.all'),
      link: `${blogs}/`,
      id: 'all',
      iconName: 'all-0'
    };
    const otherItems = (data || []).map((slug: TCategory) => ({
      title: t(`blogs.category.${slug}`),
      link: `${blogs}/category/${slug}`,
      id: slug,
      iconName: transformCmsIcons(`blogs-category-${slug}`)
    }));
    return isAuthenticated
      ? [myItem, allItem, ...otherItems]
      : [allItem, ...otherItems];
  }, [t, data]);

  return (
    <Box className={styles.wrapperSlider}>
      <SlideMenu
        isWithButtons={isWithButtons}
        sliderClassName={styles.slider}
        className={className}
      >
        <TabsNavigation
          items={items}
          linkClassName={styles.link}
          activeClassName={styles.active}
          listClassName={styles.wrap}
          itemClassName={styles.item}
          isActiveFavorite
        />
      </SlideMenu>
    </Box>
  );
};

export default Category;
