import { ComingSoon } from '@monorepo/common';
import { SOCIAL_FRAME_NAME } from '@monorepo/messenger';

import CommonChatFields from './components/CommonChatFields';
import ExternalChatFields from './components/ExternalChatFields';
import FriendshipFields from './components/FriendshipFields';

const formFieldsMap = {
  [SOCIAL_FRAME_NAME.EXTERNAL_CHAT]: ExternalChatFields,
  [SOCIAL_FRAME_NAME.COMMON_CHAT]: CommonChatFields,
  [SOCIAL_FRAME_NAME.FRIENDSHIP]: FriendshipFields,
  [SOCIAL_FRAME_NAME.MESSENGER]: ComingSoon,
  [SOCIAL_FRAME_NAME.FRIENDS_LIST]: ComingSoon
};

export default formFieldsMap;
