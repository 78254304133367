import React, { useEffect, useRef, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useGetUser, useSaveImage } from '@monorepo/auth';
import { DATE_FORMAT, EmptyState, TBlogInfoUpdate } from '@monorepo/common';
import {
  Form,
  InputField,
  maxLengthValidator,
  minLengthValidator,
  requiredValidator,
  SelectField,
  TextAreaField,
  UploadDocumentInput
} from '@monorepo/forms';
import { formatUnixTime, toUnix } from '@monorepo/helpers';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { CustomFC } from '@monorepo/type';
import {
  Box,
  Button,
  BUTTON_INTENT,
  BUTTON_SIZE,
  FIELD_TONE,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  Label,
  UI_SIZES
} from '@monorepo/ui-kit';

import BlogDateRange from '../../../../common/BlogDateRange';
import BlogPreviewButton from '../../../../common/BlogPreviewButton';
import DeleteBlogButton from '../../../../common/DeleteBlogButton';
import SubmitButton from '../../../../common/SubmitButton';
import useBlogUpdate from '../../../../hooks/selectors/useBlogUpdate';
import useGetBlogsCategory from '../../../../hooks/useGetBlogsCategory';
import useUploadFile from '../../../../hooks/useUploadFile';
import { TBlogMod } from '../../../../types';

import styles from './index.module.css';

const EditForm: CustomFC<TBlogMod> = ({
  defaultValues,
  files = '.jpg',
  uploadMaxSize = 1
}) => {
  const navigate = useNavigate();
  const { t } = useI18n(NAMESPACES.SOCIAL);
  const { data: categories } = useGetBlogsCategory();
  const options = (categories || []).map((slug: string) => ({
    label: t(`blogs.category.${slug}`),
    value: slug
  }));
  const { id, linkToPicture = '', playerId } = defaultValues || {};
  const formContextRef = useRef<FieldValues | null>(null);
  const { save } = useBlogUpdate();
  const { save: saveImage } = useSaveImage();
  const refImage = useRef();
  const [fileName, setFileName] = useState(linkToPicture);

  useEffect(() => {
    if (!fileName && linkToPicture) {
      setFileName(linkToPicture);
    }
  }, [linkToPicture]);

  const fieldName = 'linkToPicture';
  const { onChangeHandler } = useUploadFile({
    uploadMaxSize,
    setFileName,
    formContextRef,
    refImage,
    saveImage,
    fieldName
  });

  const convertedStartTime = formatUnixTime(
    defaultValues.startTime,
    DATE_FORMAT.LONG
  );
  const convertedEndTime = formatUnixTime(
    defaultValues.endTime,
    DATE_FORMAT.LONG
  );
  const newValue = {
    ...defaultValues,
    linkToPicture: fileName,
    startTime: convertedStartTime,
    endTime: convertedEndTime
  };

  const onSave = async (data: TBlogInfoUpdate) => {
    // @ts-ignore
    const mainData = formContextRef.current.getValues();
    try {
      await save({
        ...data,
        ...mainData,
        linkToVideo: mainData.linkToVideo === '' ? null : mainData.linkToVideo,
        startTime: toUnix(mainData.startTime, DATE_FORMAT.LONG),
        endTime: toUnix(mainData.endTime, DATE_FORMAT.LONG),
        id
      });
      return true;
    } catch (e) {
      return false;
    }
  };

  const { data: profile = {} } = useGetUser();
  const { id: ownerId } = profile;
  const isOwner = playerId === ownerId;

  if (!isOwner) {
    return (
      <EmptyState icon="user" title="Wrong path" subtitle="Access denided" />
    );
  }

  return (
    <Form
      className={styles.form}
      ref={formContextRef}
      onSubmit={onSave}
      formSettings={{
        defaultValues: newValue,
        values: newValue,
        mode: 'onChange'
      }}
    >
      <BlogDateRange />
      <FlexBox direction={FLEX_DIRECTION.column} className={styles.titleRow}>
        <InputField
          id="title"
          name="title"
          label={`${t('blogs.title.label')} *`}
          placeholder={t('blogs.title.placeholder')}
          toneType={FIELD_TONE.light}
          className={styles.title}
          max={60}
          maxLength={60}
          rules={{
            ...requiredValidator(),
            ...minLengthValidator(15, t('blogs.min.field.error', { value: 15 }))
          }}
        />
        <SelectField
          id="tags"
          name="tags"
          options={options}
          label={`${t('blogs.category.label')} *`}
          toneType={FIELD_TONE.light}
          inputClassName={styles.select}
          labelClassName={styles.label}
          className={styles.category}
          errorClassName={styles.error}
        />
      </FlexBox>
      <TextAreaField
        id="description"
        name="description"
        label={`${t('blogs.description.label')} *`}
        placeholder={t('blogs.description.placeholder')}
        toneType={FIELD_TONE.light}
        inputClassName={styles.descr}
        rules={{
          ...minLengthValidator(
            200,
            t('blogs.min.field.error', { value: 200 })
          ),
          ...maxLengthValidator(
            1000,
            t('blogs.max.field.error', { value: 1000 })
          )
        }}
      />
      <Box>
        <Label className={styles.label} toneType={FIELD_TONE.light}>
          {t('blogs.upload.picture')}
        </Label>
        <UploadDocumentInput
          ref={refImage}
          id="file"
          onChange={onChangeHandler}
          className={styles.uploadInput}
          title={fileName ? t('image.attach.another') : t('image.attach')}
          accept={files}
          inputClassName={
            fileName ? styles.fakeInputUpload : styles.fakeInputUploadEmpty
          }
        />
        <Label className={styles.label} toneType={FIELD_TONE.light}>
          {files} {uploadMaxSize}Mb
        </Label>
        {fileName && (
          <img src={fileName} alt="" className={styles.imageUploaded} />
        )}
      </Box>
      <TextAreaField
        id="body"
        name="body"
        label={`${t('blogs.content.label')} *`}
        placeholder={`${t('blogs.content.placeholder')} *`}
        toneType={FIELD_TONE.light}
        className={styles.content}
        inputClassName={styles.inputContent}
      />
      <InputField
        id="linkToVideo"
        name="linkToVideo"
        placeholder="https://"
        label={t('blogs.video.label')}
        toneType={FIELD_TONE.light}
        maxLength={1024}
      />
      <FlexBox
        align={FLEX_ALIGN.center}
        justify={FLEX_JUSTIFY.between}
        className={styles.control}
        direction={FLEX_DIRECTION.row}
        mb={UI_SIZES.m}
      >
        <SubmitButton size={BUTTON_SIZE.h40} className={styles.save}>
          {t('blogs.update')}
        </SubmitButton>
        <Button
          className={styles.cancel}
          toneType={FIELD_TONE.custom}
          intent={BUTTON_INTENT.transparent}
          onClick={() => navigate(-1)}
          size={BUTTON_SIZE.h40}
        >
          {t('blogs.cancel')}
        </Button>
        <BlogPreviewButton classNamePreview={styles.preview} />
        <DeleteBlogButton id={id as number} className={styles.delete} />
      </FlexBox>
    </Form>
  );
};
export default EditForm;
