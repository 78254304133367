import React from 'react';
import { NavLink } from 'react-router-dom';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';

import styles from './index.module.css';

const BlogsCategory = ({
  icon = '',
  slug = '',
  src = '',
  className = '',
  iconClassName = '',
  disabled = false
}: {
  icon: string;
  slug: string;
  src: string;
  className?: string;
  iconClassName?: string;
  disabled?: boolean;
}) => {
  const { t } = useI18n(NAMESPACES.SOCIAL);
  const linkClassName = disabled
    ? `${className} ${styles.disabled}`
    : className;
  return (
    <NavLink to={src} className={linkClassName}>
      {icon && <Icon name={icon} className={iconClassName} />}
      {t(`blogs.category.${slug}`)}
    </NavLink>
  );
};
export default BlogsCategory;
