import React from 'react';

import {
  TAuthData,
  useAuthLoginSWARM,
  useCheckNickOnLogin,
  useRequestSession,
  useSelectAuthData
} from '@monorepo/auth';
import { ErrorBoundary, useDisableZoom } from '@monorepo/common';
import { config, i18n, useLanguage } from '@monorepo/i18n';
import { CookieAgreeNotification } from '@monorepo/notification';
import { useAddSocialsHandlers } from '@monorepo/socials';

import CommonApp from '../CommonApp';
import Page from '../Page';

import i18nSettings from './i18n-settings.json';
import siteSettings from './settings.json';

import './App.css';

const { config: i18nConfig } = i18nSettings;
const i18nConfigSite = { ...config, ...i18nConfig };
// @ts-ignore
i18n.init(i18nConfigSite);

const Session = () => {
  const authData = useSelectAuthData();
  useRequestSession(authData as TAuthData);
  useLanguage();
  useAddSocialsHandlers();
  useDisableZoom();
  useAuthLoginSWARM();
  useCheckNickOnLogin();

  return null;
};

const App = () => (
  <ErrorBoundary>
    <CommonApp siteSettings={siteSettings}>
      <Session />
      <Page>
        <CookieAgreeNotification logoComponentId="logo-1" />
      </Page>
    </CommonApp>
  </ErrorBoundary>
);

export default App;
