import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeviceDetector } from 'betkit-ui-device-detector';

import {
  useGetNotifications,
  useNotificationsActions,
  useSelectNotificationsData
} from '@monorepo/cms';
import { BackHeaderCommon, EmptyState } from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import {
  Box,
  Button,
  BUTTON_INTENT,
  FIELD_TONE,
  NotificationsSkeleton
} from '@monorepo/ui-kit';

import CMSNotificationDetails from '../CMSNotificationDetails';
import CMSNotificationItem from '../CMSNotificationItem';

import styles from './index.module.css';

const CMSNotificationsList = ({
  slug,
  toneType,
  textSize,
  handleClose
}: {
  slug: string;
  toneType: FIELD_TONE;
  textSize: any;
  handleClose: () => void;
}) => {
  const { t } = useI18n(NAMESPACES.COMMON);
  const { isDesktop } = useDeviceDetector();
  const navigate = useNavigate();
  const { data = [], isFetching } = useGetNotifications(slug);
  const [activeItem, setActiveItem] = useState(null);
  const { setNotification } = useNotificationsActions();
  const readNotificationData = useSelectNotificationsData();

  useEffect(() => {
    setActiveItem(null);
  }, [data]);

  const goBack = () => {
    navigate(-1);
  };

  const handleClick = (value: any) => {
    setActiveItem(value);
    if (value) {
      setNotification({ categoryId: slug, notificationId: value.id });
    }
  };

  const checkReadNotification = (id: number): boolean =>
    readNotificationData[slug] && readNotificationData[slug].indexOf(id) !== -1;

  if (isFetching) {
    return <NotificationsSkeleton limit={2} />;
  }
  return (
    <>
      <BackHeaderCommon
        title={
          activeItem
            ? t('notifications.header.messages')
            : t('navigation.notifications')
        }
        tone={FIELD_TONE.dark}
        backLink={!activeItem ? undefined : () => handleClick(null)}
        titleClassName={styles.title}
        className={styles.header}
      >
        <Button isOnlyIcon intent={BUTTON_INTENT.transparent}>
          <Icon
            name="close_0"
            className={styles.icon}
            title={t('backheader.back')}
            onClick={isDesktop ? handleClose : goBack}
          />
        </Button>
      </BackHeaderCommon>

      <Box className={styles.content}>
        {!data?.length && (
          <EmptyState
            icon="updating-data_0"
            title={t('notifications.empty.title')}
            subtitle={t('notifications.empty.subtitle')}
            titleClassName={styles.emptyTitle}
            subtitleClassName={styles.emptySubtitle}
            iconClassName={styles.emptyIcon}
            className={styles.empty}
          />
        )}
        {activeItem ? (
          <CMSNotificationDetails
            data={activeItem}
            textSize={textSize}
            toneType={toneType}
          />
        ) : (
          data.map((item) => (
            <CMSNotificationItem
              data={item}
              key={item.id}
              onClick={() => handleClick(item)}
              toneType={toneType}
              textSize={textSize}
              isRead={checkReadNotification(item.id)}
            />
          ))
        )}
      </Box>
    </>
  );
};

export default CMSNotificationsList;
