import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeviceDetector } from 'betkit-ui-device-detector';

import { BackHeaderCommon, EmptyState } from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { CustomFC } from '@monorepo/type';
import {
  Box,
  Button,
  BUTTON_INTENT,
  FIELD_TONE,
  TEXT_SIZE
} from '@monorepo/ui-kit';
import { MESSAGES_TYPES } from '@monorepo/xmpp';

import { SOCIAL_NOTIFICATION_TYPE } from '../../constants';
import useSelectInboxList from '../../hooks/selectors/useSelectInboxList';
import useSelectNotifications from '../../hooks/selectors/useSelectNotifications';
import {
  createCommunityInviteToastId,
  createGroupUnreadMessageToastId,
  createUnreadMessageToastId
} from '../../utils/notifications';
import CommunityInviteNotification from '../CommunityInviteNotification';
import SocialNotification from '../SocialNotification';
import UnreadMessageNotification from '../UnreadMessageNotification';

import styles from './index.module.css';

type TSocialNotificationsList = { handleClose?: () => void };
const SocialNotificationsList: CustomFC<TSocialNotificationsList> = ({
  handleClose
}) => {
  const { t } = useI18n(NAMESPACES.COMMON);
  const notifications = useSelectNotifications();
  const navigate = useNavigate();
  const inbox = useSelectInboxList();
  const { isDesktop } = useDeviceDetector();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <BackHeaderCommon
        title={t('navigation.notifications')}
        tone={FIELD_TONE.dark}
        titleClassName={styles.title}
        className={styles.header}
      >
        <Button isOnlyIcon intent={BUTTON_INTENT.transparent}>
          <Icon
            name="close_0"
            className={styles.icon}
            title={t('backheader.back')}
            onClick={isDesktop ? handleClose : goBack}
          />
        </Button>
      </BackHeaderCommon>

      <Box className={styles.content}>
        {!(notifications?.length || inbox.length) && (
          <EmptyState
            icon="updating-data_0"
            title={t('notifications.empty.title')}
            subtitle={t('notifications.empty.subtitle')}
            titleClassName={styles.emptyTitle}
            subtitleClassName={styles.emptySubtitle}
            iconClassName={styles.emptyIcon}
            className={styles.empty}
          />
        )}
        {notifications.map(({ id, roomJid, userJid, type }) => (
          <SocialNotification
            key={id}
            id={id}
            roomJid={roomJid}
            userJid={userJid}
            type={type}
            className={styles.item}
            toneType={FIELD_TONE.dark}
            textSize={TEXT_SIZE.medium}
          />
        ))}
        {inbox.map(({ type, roomJid, from, invite }) => {
          if (type === MESSAGES_TYPES.SINGLE) {
            const id = createUnreadMessageToastId(roomJid);
            return (
              <UnreadMessageNotification
                key={id}
                id={id}
                type={SOCIAL_NOTIFICATION_TYPE.UNREAD_MESSAGE}
                roomJid={roomJid}
                userJid={from}
                className={styles.item}
                toneType={FIELD_TONE.dark}
                textSize={TEXT_SIZE.medium}
              />
            );
          }
          if (type === MESSAGES_TYPES.GROUP) {
            const id = createGroupUnreadMessageToastId(roomJid);
            return (
              <UnreadMessageNotification
                key={id}
                id={id}
                type={SOCIAL_NOTIFICATION_TYPE.COMMUNITY_UNREAD_MESSAGE}
                roomJid={roomJid}
                userJid={from}
                className={styles.item}
                toneType={FIELD_TONE.dark}
                textSize={TEXT_SIZE.medium}
              />
            );
          }
          if (invite) {
            const id = createCommunityInviteToastId(roomJid);
            return (
              <CommunityInviteNotification
                key={id}
                id={id}
                type={SOCIAL_NOTIFICATION_TYPE.COMMUNITY_INVITE}
                roomJid={roomJid}
                userJid={from}
                className={styles.item}
                toneType={FIELD_TONE.dark}
                textSize={TEXT_SIZE.medium}
              />
            );
          }
          return null;
        })}
      </Box>
    </>
  );
};

export default SocialNotificationsList;
