import { AUTH_COMPONENT_TYPES, AUTH_COMPONENTS_MAP } from '@monorepo/auth';
import { CASINO_COMPONENTS_MAP } from '@monorepo/casino';
import { CMS_COMPONENTS_MAP } from '@monorepo/cms';
import {
  COMMON_COMPONENT_TYPES,
  COMMON_COMPONENTS_MAP
} from '@monorepo/common';
import { NOTIFICATIONS_COMPONENTS_MAP } from '@monorepo/notification';
import {
  BlogAdd,
  Blogs,
  BlogsEdit,
  BlogsList,
  GameChatStandAlone,
  SOCIAL_COMPONENTS_MAP
} from '@monorepo/socials';
import { SPORTS_COMPONENTS_MAP } from '@monorepo/sports';
import { WALLET_COMPONENTS_MAP } from '@monorepo/wallet';

import AnimationView from '../AnimationView';
import EditProfileContacts from '../EditProfileContacts';
import EditProfilePersonalData from '../EditProfilePersonalData';
import Footer from '../Footer';
import HeaderAuth from '../HeaderAuth';
import HeaderNavMenu from '../HeaderNavMenu';
import HeaderUserMenuUnAuthorized from '../HeaderUserMenuUnAuthorized';
import Logo from '../Logo';
import Registration from '../Registration';
import TestMessengerForm from '../TestMessengerFrames/Form';
import TestMessengerFrame from '../TestMessengerFrames/Frame';

export const CUSTOM_TYPES = {
  GAME_CHAT_STAND_ALONE: 'game_chat_stand_alone',
  BLOGS: 'blogs',
  BLOGS_EDIT: 'blogs_edit',
  BLOGS_LIST: 'blogs_list',
  BLOG_ADD: 'blog_add',
  HEADER_AUTH: 'header_auth',
  ANIMATION_VIEW: 'animation-view'
};

const componentsMap = {
  ...AUTH_COMPONENTS_MAP,
  ...CMS_COMPONENTS_MAP,
  ...CASINO_COMPONENTS_MAP,
  ...SOCIAL_COMPONENTS_MAP,
  ...SPORTS_COMPONENTS_MAP,
  ...WALLET_COMPONENTS_MAP,
  ...COMMON_COMPONENTS_MAP,
  ...NOTIFICATIONS_COMPONENTS_MAP,
  [AUTH_COMPONENT_TYPES.REGISTRATION]: Registration,
  [AUTH_COMPONENT_TYPES.HEADER_USER_MENU_UNAUTHORIZED]:
    HeaderUserMenuUnAuthorized,
  [AUTH_COMPONENT_TYPES.EDIT_PROFILE_CONTACTS]: EditProfileContacts,
  [AUTH_COMPONENT_TYPES.EDIT_PROFILE_PERSONAL_DATA]: EditProfilePersonalData,
  [CUSTOM_TYPES.ANIMATION_VIEW]: AnimationView,
  [COMMON_COMPONENT_TYPES.LOGO]: Logo,
  [COMMON_COMPONENT_TYPES.FOOTER]: Footer,
  [COMMON_COMPONENT_TYPES.HEADER_NAV_MENU]: HeaderNavMenu,
  [CUSTOM_TYPES.HEADER_AUTH]: HeaderAuth,
  [CUSTOM_TYPES.GAME_CHAT_STAND_ALONE]: GameChatStandAlone,
  [CUSTOM_TYPES.BLOGS]: Blogs,
  [CUSTOM_TYPES.BLOGS_EDIT]: BlogsEdit,
  [CUSTOM_TYPES.BLOG_ADD]: BlogAdd,
  [CUSTOM_TYPES.BLOGS_LIST]: BlogsList,
  'test-messenger-form': TestMessengerForm,
  'test-messenger-frame': TestMessengerFrame
};

export default componentsMap;
