import React from 'react';

import BlogCard from '../../../../common/BlogCard';

import styles from './index.module.css';

const BlogListing = ({ list }) =>
  (list || []).map((item) => (
    <BlogCard item={item} className={styles.card} key={item.id} />
  ));

export default BlogListing;
