import React from 'react';

import { CheckboxField, InputField, SelectField } from '@monorepo/forms';
import { FRIENDSHIP_VIEW } from '@monorepo/messenger';

const FriendshipFields = () => (
  <>
    <SelectField
      id="frameDefaultView"
      name="defaultView"
      label="Default view"
      placeholder="Select default view"
      options={[
        {
          value: '',
          label: 'none'
        },
        {
          value: FRIENDSHIP_VIEW.LOBBY,
          label: FRIENDSHIP_VIEW.LOBBY
        },
        {
          value: FRIENDSHIP_VIEW.SEARCH,
          label: FRIENDSHIP_VIEW.SEARCH
        },
        {
          value: FRIENDSHIP_VIEW.CONTACT_PREVIEW,
          label: FRIENDSHIP_VIEW.CONTACT_PREVIEW
        },
        {
          value: FRIENDSHIP_VIEW.MATCH_PREVIEW,
          label: FRIENDSHIP_VIEW.MATCH_PREVIEW
        },
        {
          value: FRIENDSHIP_VIEW.DIALOG,
          label: FRIENDSHIP_VIEW.DIALOG
        }
      ]}
    />
    <InputField
      key="1"
      id="frameContactID"
      name="contactId"
      label="Chat contact Id*"
      placeholder="Enter chat contact id"
    />
    <CheckboxField label="ReedOnly" id="readOnly" name="readOnly" />
  </>
);

export default FriendshipFields;
