import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useNavigateUnauthenticated } from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import { CustomFC } from '@monorepo/type';
import {
  Button,
  BUTTON_INTENT,
  BUTTON_SIZE,
  EllipsisText
} from '@monorepo/ui-kit';

import styles from './index.module.css';

const HeaderUserMenuUnAuthorized: CustomFC = () => {
  const { t } = useI18n(NAMESPACES.COMMON);
  const { navigateToLogin } = useNavigateUnauthenticated();
  const { generalRoutes } = useSiteSettings();
  const { registration } = generalRoutes;
  const navigate = useNavigate();

  const handleLogin = useCallback(() => navigateToLogin(), []);

  const handleRegister = useCallback(() => {
    navigate(registration);
  }, []);

  return (
    <>
      <Button
        intent={BUTTON_INTENT.tertiary}
        className={`${styles.button} ${styles.login}`}
        size={BUTTON_SIZE.h28}
        onClick={handleLogin}
        data-custom="login-button"
      >
        <EllipsisText>{t('headerusermenu.login').toLowerCase()}</EllipsisText>
      </Button>
      <Button
        intent={BUTTON_INTENT.primary}
        className={`${styles.button} ${styles.signup}`}
        size={BUTTON_SIZE.h28}
        onClick={handleRegister}
        data-custom="signup-button"
      >
        <EllipsisText>{t('headerusermenu.signup').toLowerCase()}</EllipsisText>
      </Button>
    </>
  );
};

export default HeaderUserMenuUnAuthorized;
