import { useSelectAuthData } from '@monorepo/auth';
import {
  useGetBlogsByUserQuery,
  useGetBlogsQuery
} from '@monorepo/common/src/store/userGeneratedContentApi';

const useGetBlogs = ({
  path,
  limit,
  offset,
  orderBy,
  sort
}: {
  path: string;
  limit: number;
  offset: number;
  orderBy: string;
  sort: string;
}) => {
  const authData = useSelectAuthData();
  if (path === 'my') {
    return useGetBlogsByUserQuery({
      userId: authData?.ownerId || authData?.userId,
      limit,
      offset,
      orderBy,
      sort
    });
  }
  return useGetBlogsQuery({
    tag: path,
    limit,
    offset,
    orderBy,
    sort
  });
};
export default useGetBlogs;
