import React from 'react';
import { NavLink } from 'react-router-dom';

import { AVATAR_SIZE } from '@monorepo/files';
import { useSiteSettings } from '@monorepo/site-settings';
import { Component } from '@monorepo/type';
import { Avatar, Box } from '@monorepo/ui-kit';

import useChatUser from '../../hooks/useChatUser';

import styles from './index.module.css';

type TBlogAvatar = {
  className?: string;
  userId: string;
};
const BlogAvatar: Component<TBlogAvatar> = ({
  className = '',
  userId = ''
}) => {
  const { generalRoutes } = useSiteSettings();
  const { profile } = generalRoutes;
  const editorLink = `${profile}/${userId}`;
  const { avatar = '', displayName = '' } = useChatUser(userId);
  const avatarSrc = `${avatar}/${AVATAR_SIZE.L}`;

  return (
    <NavLink to={editorLink} className={styles.link}>
      <Avatar
        className={`${styles.wrap} ${className}`}
        imgSrc={avatarSrc}
        alt={displayName}
        nickname={displayName}
      />
      <Box className={styles.name}>{displayName}</Box>
    </NavLink>
  );
};

export default BlogAvatar;
