import React, { ElementRef, useEffect, useRef } from 'react';

import {
  Form,
  requiredValidator,
  SelectField,
  SubmitButton
} from '@monorepo/forms';
import { toSelectOptions, useSearchParamsWithParsing } from '@monorepo/helpers';
import { LANGUAGE_CODES_MAP, useI18n } from '@monorepo/i18n';
import { POST_MESSAGE_TYPE, SOCIAL_FRAME_NAME } from '@monorepo/messenger';
import { Component } from '@monorepo/type';

type FormData = {
  language: string;
};

type TOption = {
  label: string;
  value: string;
};

type Props = {
  frameName: SOCIAL_FRAME_NAME;
  onSubmit: () => void;
};

const defaultValues = {
  language: ''
};

const LanguageForm: Component<Props> = ({ className, frameName, onSubmit }) => {
  const formRef = useRef<ElementRef<typeof Form>>(null);
  const frameRef = useRef<HTMLIFrameElement>(
    document.getElementById(frameName) as HTMLIFrameElement
  );
  const { searchParams, setSearchParamsObject } = useSearchParamsWithParsing();
  const { language } = useI18n();
  const values = {
    language: searchParams.language || language
  };
  const languages = Object.values(LANGUAGE_CODES_MAP);

  const handleSubmit = (data: FormData) => {
    frameRef.current?.contentWindow?.postMessage(
      {
        type: POST_MESSAGE_TYPE.CHANGE_LANGUAGE,
        data
      },
      '*'
    );
    setSearchParamsObject(data);
    onSubmit();
  };

  useEffect(() => {
    const node = document.getElementById(frameName);
    if (node) {
      frameRef.current = node as HTMLIFrameElement;
    }
  }, [frameName]);

  return (
    // @ts-ignore
    <Form<FormData>
      ref={formRef}
      onSubmit={handleSubmit}
      formSettings={{ defaultValues, values, mode: 'onSubmit' }}
      className={className}
    >
      <SelectField
        id="changeLanguage"
        name="language"
        label="Select language*"
        rules={{ ...requiredValidator() }}
        options={toSelectOptions(languages, '', '').map(
          ({ label, value }): TOption => ({
            label: label as string,
            value
          })
        )}
      />
      <SubmitButton mode="onSubmit" checkDirty={false}>
        Change language
      </SubmitButton>
    </Form>
  );
};

export default LanguageForm;
