import React from 'react';

import {
  FavoriteButton,
  LIKE_TYPE,
  LikeButton,
  ShareButton
} from '@monorepo/common';
import { Component } from '@monorepo/type';
import { FLEX_ALIGN, FlexBox } from '@monorepo/ui-kit';

import styles from './index.module.css';

type TCard = {
  id?: number;
  disabled?: boolean;
  href: string;
  title: string;
  type: LIKE_TYPE;
};
const BlogCardInfo: Component<TCard> = ({
  id,
  className = '',
  disabled = false,
  href = '',
  title = '',
  type
}) => {
  const wrapClassName = `${styles.wrap} ${className} ${
    disabled ? styles.disabled : ''
  }`;
  return (
    <FlexBox align={FLEX_ALIGN.center} className={wrapClassName}>
      <FlexBox align={FLEX_ALIGN.center} className={styles.buttons}>
        <>
          <LikeButton
            id={id}
            className={styles.action}
            type={type}
            dataCustom="blog-like-custom"
          />
        </>
        <ShareButton
          className={styles.action}
          url={href}
          title={title}
          size="medium"
          dataCustom="blog-share-custom"
        />
        <FavoriteButton
          type={type}
          id={id}
          className={styles.action}
          dataCustom="blog-favorite-custom"
        />
      </FlexBox>
    </FlexBox>
  );
};

export default BlogCardInfo;
