import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDeviceDetector } from 'betkit-ui-device-detector';

import {
  EmptyState,
  LIKE_TYPE,
  LikesProvider,
  useScrollToTop
} from '@monorepo/common';
import { useSearchParamsWithParsing } from '@monorepo/helpers';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';
import { ComponentWithConfig } from '@monorepo/type';
import {
  Box,
  BUTTON_INTENT,
  BUTTON_SIZE,
  FIELD_TONE,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FLEX_WRAP,
  FlexBox,
  LinkButton,
  PaddingWrapper,
  Pagination
} from '@monorepo/ui-kit';

import useGetBlogs from '../../hooks/useGetBlogs';

import BlogListing from './component/BlogListing';
import Category from './component/Category';

import styles from './index.module.css';

type TConfig = {
  limit?: number;
};
const BlogsList: ComponentWithConfig<TConfig> = ({ config }) => {
  const { limit = 4 } = config;
  const { isDesktop } = useDeviceDetector();
  const { t } = useI18n(NAMESPACES.SOCIAL);
  const { generalRoutes } = useSiteSettings();
  const { blogs } = generalRoutes;
  const path = useParams<Record<string, string>>();
  const { searchParams, setSearchParam } = useSearchParamsWithParsing();
  const { paginate } = searchParams;
  const [offset, setOffset] = useState(0);
  const { data, isFetching } = useGetBlogs({
    path: path.id as string,
    limit,
    offset,
    orderBy: 'create_time',
    sort: 'desc'
  });
  const { list: blogList = [], total_count: totalCount = 0 } = data || {};
  const likeIds = useMemo(
    () => blogList?.map((blog: any) => `${blog.id}`) || [],
    [blogList]
  );

  const totalPages = Math.ceil(totalCount / limit);
  const handlePageChange = useCallback(
    (page) => setSearchParam('paginate', page),
    [setSearchParam]
  );

  const getCurrentPage = useCallback(() => {
    if (!paginate) {
      return 1;
    }
    return Number(paginate) > Number(totalPages)
      ? Number(totalPages)
      : Number(paginate);
  }, [paginate, totalPages]);
  const currentPage = getCurrentPage();
  const showPagination = totalPages > 1;

  useEffect(() => {
    if (!paginate) {
      return;
    }
    setOffset((Number(paginate) - 1) * limit);
  }, [paginate]);

  useScrollToTop();

  return (
    <LikesProvider list={likeIds}>
      <PaddingWrapper isOnlyMobile className={styles.root}>
        <Box>
          <FlexBox
            className={styles.head}
            justify={FLEX_JUSTIFY.center}
            align={FLEX_ALIGN.center}
          >
            {t('blogs')}
          </FlexBox>
          <Box className={styles.category}>
            <Box>
              <FlexBox
                className={styles.categoryWrap}
                justify={FLEX_JUSTIFY.between}
                align={FLEX_ALIGN.center}
              >
                <Category />
                <LinkButton
                  to={`${blogs}/add`}
                  toneType={FIELD_TONE.light}
                  intent={BUTTON_INTENT.primary}
                  className={styles.add}
                  size={BUTTON_SIZE.h40}
                >
                  {!isDesktop ? (
                    <Icon name="message-add" className={styles.addIcon} />
                  ) : (
                    t('blogs.add.new.blog')
                  )}
                </LinkButton>
              </FlexBox>
            </Box>
          </Box>
          {blogList?.length === 0 ? (
            <EmptyState
              icon="empty_favorites-0"
              title={t('blogs.empty')}
              iconClassName={styles.icon}
              className={styles.noBlogs}
            />
          ) : (
            <>
              <FlexBox
                direction={FLEX_DIRECTION.row}
                wrap={FLEX_WRAP.wrap}
                className={styles.grid}
              >
                <BlogListing list={blogList} />
              </FlexBox>
              {showPagination && (
                <Pagination
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  isFetching={isFetching}
                />
              )}
            </>
          )}
        </Box>
      </PaddingWrapper>
    </LikesProvider>
  );
};
export default BlogsList;
