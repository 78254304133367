import React, { useEffect, useMemo, useState } from 'react';
import { useDeviceDetector } from 'betkit-ui-device-detector';

import { usePostMessageListener } from '@monorepo/helpers';
import { Icon } from '@monorepo/icons';
import {
  SENT_POST_MESSAGE_TYPE,
  SOCIAL_FRAME_NAME,
  TSentSocialMessage
} from '@monorepo/messenger';
import {
  Button,
  BUTTON_INTENT,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  Portal,
  Shutter,
  useShutterRef
} from '@monorepo/ui-kit';

import CloseButton from './components/CloseButton';
import FrameForm from './components/FrameForm';
import LanguageForm from './components/LanguageForm';
import SelectFrameForm from './components/SelectFrameForm';

import styles from './index.module.css';

const TestMessengerForm = () => {
  const { isMobile } = useDeviceDetector();
  const { shutterRef, setShow, setHide } = useShutterRef();
  const [frameName, setFrameName] = useState<SOCIAL_FRAME_NAME | undefined>(
    undefined
  );

  useEffect(() => {
    setShow();
  }, []);

  usePostMessageListener<TSentSocialMessage>((e) => {
    if (e && e.data) {
      if (e.data?.type === SENT_POST_MESSAGE_TYPE.FRAME_INIT) {
        setFrameName(e.data.data.name);
      } else if (e.data?.type === SENT_POST_MESSAGE_TYPE.REDIRECT) {
        // eslint-disable-next-line no-console
        console.log(e.data?.type, e.data);
      }
    }
  });

  const filtersComp = useMemo(
    () => (
      <FlexBox direction={FLEX_DIRECTION.column} className={styles.wrap}>
        <SelectFrameForm />
        {frameName && <FrameForm frameName={frameName} onSubmit={setHide} />}
        {frameName && <LanguageForm frameName={frameName} onSubmit={setHide} />}
      </FlexBox>
    ),
    [frameName]
  );

  if (isMobile) {
    return (
      <>
        <Button
          intent={BUTTON_INTENT.transparent}
          isOnlyIcon
          onClick={setShow}
          className={styles.openButton}
        >
          <FlexBox
            direction={FLEX_DIRECTION.column}
            justify={FLEX_JUSTIFY.center}
          >
            <Icon name="menu_2" title="" />
          </FlexBox>
        </Button>
        <Portal>
          {/* @ts-ignore */}
          <Shutter side="left" ref={shutterRef} vertical="stretch" isFullWidth>
            <CloseButton className={styles.closeButton} />
            {filtersComp}
          </Shutter>
        </Portal>
      </>
    );
  }

  return filtersComp;
};

export default TestMessengerForm;
