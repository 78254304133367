import React from 'react';

import { BackHeaderCommon, useScrollToTop } from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import { ComponentWithConfig } from '@monorepo/type';
import {
  FIELD_TONE,
  FLEX_DIRECTION,
  FlexBox,
  PaddingWrapper
} from '@monorepo/ui-kit';

import { TBlogMod } from '../../types';

import EditForm from './component/EditForm';

import styles from './index.module.css';

const BlogAdd: ComponentWithConfig<TBlogMod> = ({ config }) => {
  const { t } = useI18n(NAMESPACES.SOCIAL);
  const { generalRoutes } = useSiteSettings();
  const { blogs } = generalRoutes;
  const { files, uploadMaxSize } = config;
  useScrollToTop();

  return (
    <PaddingWrapper className={styles.root}>
      <FlexBox direction={FLEX_DIRECTION.column}>
        <BackHeaderCommon
          title={t('blogs.back')}
          backLink={blogs}
          tone={FIELD_TONE.light}
        />
        <EditForm files={files} uploadMaxSize={uploadMaxSize} />
      </FlexBox>
    </PaddingWrapper>
  );
};
export default BlogAdd;
