import React from 'react';

import { useGetUser } from '@monorepo/auth';
import { transformCmsIcons, YoutubeVideo } from '@monorepo/cms';
import { LIKE_TYPE } from '@monorepo/common';
import { formatDate, formatUnixTime } from '@monorepo/helpers';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';
import {
  Box,
  BUTTON_INTENT,
  FLEX_ALIGN,
  FLEX_JUSTIFY,
  FlexBox,
  LinkButton
} from '@monorepo/ui-kit';

import { TBlogCard } from '../../types';
import youTubeGetID from '../../utils/youTubeGetID';
import BlogAvatar from '../BlogAvatar';
import BlogCardInfo from '../BlogCardInfo';
import BlogsCategory from '../BlogsCategory';
import DeleteBlogButton from '../DeleteBlogButton';

import styles from './index.module.css';

const BlogContent = ({
  blog,
  isPreview = false,
  className = ''
}: {
  blog: TBlogCard;
  isPreview?: boolean;
  className?: string;
}) => {
  const { generalRoutes } = useSiteSettings();
  const { blogs } = generalRoutes;
  const {
    title = 'Default Title',
    description = 'Default Description',
    body = '',
    linkToVideo = '',
    linkToPicture = '',
    createTime,
    tags,
    likes,
    stars,
    viewsCount = 0,
    playerId = '',
    id
  } = blog || {};
  const { t } = useI18n(NAMESPACES.SOCIAL);
  const createTimeView = createTime
    ? formatUnixTime(createTime, 'dd.MM.yyyy, HH:mm')
    : formatDate(new Date(), 'dd.MM.yyyy, HH:mm');
  const { data: profile = {} } = useGetUser();
  const { id: ownerId } = profile;
  const avatarID = playerId || ownerId;
  const isOwner = playerId === ownerId;
  const viewCount = `| ${viewsCount} ${t('blogs.views')}`;
  const tag = isPreview ? tags : tags[0];

  return (
    <Box className={`${styles.content} ${className}`}>
      <FlexBox align={FLEX_ALIGN.center} className={styles.head}>
        <Box className={styles.title}>{title}</Box>
        {!isPreview && isOwner && (
          <LinkButton
            to={`${blogs}/${id}/edit`}
            isOnlyIcon
            className={styles.edit}
            intent={BUTTON_INTENT.transparent}
          >
            <Icon name="edit_0" className={styles.icon} />
          </LinkButton>
        )}
        {!isPreview && isOwner && (
          <DeleteBlogButton id={id} className={styles.delete} />
        )}
      </FlexBox>
      <FlexBox
        align={FLEX_ALIGN.center}
        justify={FLEX_JUSTIFY.between}
        className={styles.info}
      >
        <FlexBox align={FLEX_ALIGN.center}>
          <BlogsCategory
            src={`${blogs}/category/${tag}`}
            icon={transformCmsIcons(`blogs-category-${tag}`)}
            slug={tag}
            disabled={isPreview}
            className={styles.category}
            iconClassName={styles.categoryIcon}
          />
          <Box className={styles.date}>
            {createTimeView} {!isPreview && viewCount}
          </Box>
        </FlexBox>
        {id && (
          <BlogCardInfo
            className={styles.fullInfo}
            id={id}
            title={title}
            likes={likes}
            stars={stars}
            disabled={isPreview}
            type={LIKE_TYPE.POST}
            href={`${blogs}/${id}`}
          />
        )}
      </FlexBox>
      <FlexBox
        className={`${styles.editor} ${isPreview ? styles.disabled : ''}`}
      >
        <BlogAvatar className={styles.author} userId={avatarID} />
      </FlexBox>
      <Box className={styles.description}>{description}</Box>
      {linkToPicture && (
        <Box className={styles.img}>
          <img src={linkToPicture} alt="" />
        </Box>
      )}
      <Box className={styles.text}>{body}</Box>
      {linkToVideo && (
        <Box className={styles.video}>
          <YoutubeVideo videoId={youTubeGetID(linkToVideo)} />
        </Box>
      )}
    </Box>
  );
};
export default BlogContent;
