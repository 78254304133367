import React, { ElementRef, useRef } from 'react';

import {
  Form,
  InputField,
  requiredValidator,
  SelectField,
  SubmitButton
} from '@monorepo/forms';
import { toSelectOptions, useSearchParamsWithParsing } from '@monorepo/helpers';
import { SOCIAL_FRAME_LIST, SOCIAL_FRAME_NAME } from '@monorepo/messenger';

type FormData = {
  frameName: SOCIAL_FRAME_NAME;
  theme?: string;
};

const defaultValues = {
  frameName: '',
  theme: ''
};

const SelectFrameForm = () => {
  const formRef = useRef<ElementRef<typeof Form>>(null);
  const { searchParams, setSearchParamsObject } = useSearchParamsWithParsing();
  const frameOptions = toSelectOptions(SOCIAL_FRAME_LIST, '', '');
  const values = {
    frameName: searchParams.frameName || '',
    theme: searchParams.theme || ''
  };

  const handleSubmit = (data: FormData) => setSearchParamsObject(data);

  return (
    // @ts-ignore
    <Form<FormData>
      ref={formRef}
      onSubmit={handleSubmit}
      formSettings={{ defaultValues, values, mode: 'onSubmit' }}
    >
      <SelectField
        id="frameName"
        name="frameName"
        label="Frame name*"
        placeholder="Select frame name"
        rules={{ ...requiredValidator() }}
        options={frameOptions}
      />
      <InputField
        id="frameTheme"
        name="theme"
        label="Frame theme"
        placeholder="Enter frame theme"
        hint="e.g. betchain, dreamcity"
      />
      <SubmitButton mode="onSubmit">Set frame name</SubmitButton>
    </Form>
  );
};

export default SelectFrameForm;
