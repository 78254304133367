import React from 'react';

import { useIsAuthenticated } from '@monorepo/auth';
import { transformMenuItems, useGetMenu } from '@monorepo/cms';
import {
  CustomButton,
  MetaVerseButton,
  useCustomButton
} from '@monorepo/common';
import { ComponentWithConfig } from '@monorepo/type';
import { HeaderNavMenu as UiHeaderNavMenu, TMenuItem } from '@monorepo/ui-kit';

type THeaderNavMenu = {
  config: {
    menuId: number;
    items: TMenuItem[];
  };
};

const HeaderNavMenu: ComponentWithConfig<THeaderNavMenu> = ({ config }) => {
  // @ts-ignore
  const { menuId, additional } = config;
  const customButton = useCustomButton();
  const { isLoading, data: cmsMenuItems } = useGetMenu(menuId);
  const { isAuthenticated } = useIsAuthenticated();

  if (isLoading) {
    return null;
  }
  const transFormedMenuItems = transformMenuItems(
    cmsMenuItems,
    isAuthenticated
  );

  return (
    <UiHeaderNavMenu items={transFormedMenuItems}>
      {additional && <MetaVerseButton item={additional} />}
      {customButton && <CustomButton item={customButton} />}
    </UiHeaderNavMenu>
  );
};

export default HeaderNavMenu;
